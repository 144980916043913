const translateProduct = (productName) => {
    switch(productName){
        case "Akademia Membership":
            return "[PL] Membership Academy";
        case "Droga do 100 MLN":
            return "[PL] Road for 100 MLN";
        case "Od Developera do Foundera":
            return "[PL] From Developer to Founder";
        case "Klub SaaS":
            return "[PL] Saas Club";
        default:
            return productName;
    }
};
export {
    translateProduct
};