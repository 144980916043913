import { Col } from "antd";
import React from 'react';

export function CustomCol({ width, span, maxSpan, align, offset, children }) {
    return width <= 1200 ?
        (<Col align={align} span={maxSpan} offset={offset}>{children}</Col>) :
        (
            span ?
                (<Col align={align} offset={offset} span={span}>{children}</Col>) :
                (<Col align={align} offset={offset}>{children}</Col>)
        );
}