import axios from 'axios';


const getVipList = async (address) => {
   const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/available-nfts`, {params: { wallet: address.toLowerCase()}});
   return response.data;
}

const getVipDetails = async (tokenId) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/check-token`, {params: {vipNftNo: tokenId}});
    return response.data;
}

const postForm = async ({ name, surname, email, vipNftNo, productId, acceptPP, signature }) => {
    const response = await axios.post( `${process.env.REACT_APP_BACKEND_API_URL}/product`, {
        name,
        surname,
        email,
        vipNftNo,
        productId,
        acceptPP,
        signature
    });

    return response;
}

export {
    getVipList,
    getVipDetails,
    postForm
};