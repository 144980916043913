import { Button, Card, Result, Row } from 'antd';
import React from 'react';
import { CustomCol } from "./CustomCol";
import moment from "moment";

const VipList = ({ vipList, selectVipNft, currentSize }) => {
    return (
        vipList.length === 0 ?
            <Row justify="center">
                <Result
                    title={ `Nie znaleźliśmy żadnego VIP NFT na połączonym portfelu.` }
                >
                </Result>
            </Row>
            :
            <Row gutter={[8,8]}>
                {
                    vipList &&
                    vipList.map(item => {
                        const canChooseNewProduct = moment(item.expirationDateTime).isSameOrBefore(moment());
                        return(<CustomCol span={4} offset={1} width={currentSize} key={`vipNft${item.value}`}>
                                <Card
                                    hoverable={!item.relatedProduct}
                                    cover={<img alt=""
                                                src="https://bafybeiead52a4vlpzug5pxs362wwtlcpfkkwz3emo77oxcq6r2xyg62vxu.ipfs.nftstorage.link/"/>}
                                    actions={!item.relatedProduct || item.ownerChanged || canChooseNewProduct ? [
                                        <Button type="primary"
                                                onClick={() => selectVipNft(item)}
                                        >
                                            Wybierz produkt
                                        </Button>
                                    ] : []}
                                >
                                    <Card.Meta title={item.name}
                                               description={!!item.relatedProduct ? `${item.relatedProduct}` : ""}/>
                                    {
                                        !!item.relatedProduct &&
                                        <Card.Meta
                                            description={`Przypisany do ${moment(item.expirationDateTime).format("Do MMMM YYYY")}`}/>
                                    }
                                </Card>
                            </CustomCol>);
                        }
                    )
                }
            </Row>
    );
}

export default VipList;