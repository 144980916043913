import React, { useState } from "react";
import { connect } from "react-redux";
import { Form, Row, InputNumber, Button, Card } from "antd";
import { getVipDetails } from "../utils/api/productApi";
import moment from "moment";
import { CustomCol } from "./CustomCol";
import useWindowSize from "../utils/resizeHelper";

const CheckForm = () => {
    const currentSize = useWindowSize();
    const [nftNo, setNftNo] = useState(null);
    const [nftDetails, setNftDetails] = useState(null);

    const handleSubmit = async ({ vipNftNo }) => {
        setNftDetails(null);
        const vipNftDetails = await getVipDetails(vipNftNo);
        setNftDetails(vipNftDetails);
    };

    return (
        <>
        {
            nftDetails !== null &&
            <>
                <Row justify={"center"}>
                    <CustomCol span={8} offset={1} width={currentSize} key={`vipNft${nftDetails.value}`}>
                        <Card
                            hoverable={!nftDetails.assignedProduct}
                            cover={<img alt="" src="https://bafybeiead52a4vlpzug5pxs362wwtlcpfkkwz3emo77oxcq6r2xyg62vxu.ipfs.nftstorage.link/" />}
                        >
                            <Card.Grid style={{ width: "50%", textAlign: 'left'}} hoverable={false}>
                                <Card.Meta title={nftDetails.name} description={!!nftDetails.assignedProduct ? `${nftDetails.assignedProduct}`:""} />
                                {
                                    !!nftDetails.assignedProduct &&
                                    <Card.Meta description={nftDetails.value === 'not-assigned' ? `Not assigned`: `Assigned until ${moment(nftDetails.expirationDateTime).format("Do MMMM YYYY")}`} />
                                }
                            </Card.Grid>

                            <Card.Grid style={{ width: "50%", textAlign: 'right'}} hoverable={false}>
                                <Button
                                    type={"secondary"}
                                    target={"_blank"}
                                    href={`${process.env.REACT_APP_OPEN_SEA_URL}${nftNo}`}
                                >
                                    Check details on OpenSea
                                </Button>
                            </Card.Grid>
                        </Card>
                    </CustomCol>
                </Row>
                <Row>&nbps;</Row>
            </>
        }
            <Row justify="center">
                <Form
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={ {
                        span: 12
                    }}
                    layout="horizontal"
                    size={"large"}
                    onFinish={handleSubmit}
                >
                    <Form.Item label={"Vip NFT No. "} name="vipNftNo" style={{ width: "100%" }}
                               rules={[{
                                   validator: (_, value) =>
                                       value ? Promise.resolve() : Promise.reject(new Error('Provide NFT VIP token id between 1-2500'))
                               }]}
                               wrapperCol={{
                                   span: 16,
                               }}>
                        <InputNumber min={1} max={2500} placeholder="Token id 1-2500" style={{width: "100%"}} onChange={setNftNo} />
                    </Form.Item>
                    <Form.Item wrapperCol={{
                        offset: 4,
                        span: 20,
                    }}>
                        <Button
                            type={"primary"}
                            className={"bfc-green"}
                            size={"large"}
                            block
                            htmlType="submit"
                            disabled={nftNo === null}
                        >
                            Check token
                        </Button>
                    </Form.Item>
                </Form>
            </Row>
        </>
    );
}

const mapStateToProps = (state) => ({
});


export default connect(mapStateToProps, {})(CheckForm);