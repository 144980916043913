const provideName = (productName) => {
    switch(productName){
        case "Akademia Membership":
            return "Akademia Membership";
        case "Droga do 100 MLN":
            return "Droga do 100 MLN";
        case "Od Developera do Foundera":
            return "Akademia SaaS";
        // case "Klub SaaS":
        //     return "Klub SaaS";
        default:
            return productName;
    }
};
export {
    provideName
};