import React from "react";
import { Typography } from "antd";
const { Title } = Typography;

export function NoWalletDetected() {
  return (
    <Title level={2}>
      No Ethereum wallet was detected. <br />
      Please install{" "}
      <a
        href="http://metamask.io"
        target="_blank"
        rel="noopener noreferrer"
      >
        MetaMask
      </a>
      .
    </Title>
  );
}