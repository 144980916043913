import { createSlice } from '@reduxjs/toolkit';

const walletSlice = createSlice({
    name: 'wallet',
    initialState: {
        currentAddress: null,
        contractAddress: null,
        clearSigner: false
    },
    reducers: {
        initContractAddress: (state, action) => {
            state.contractAddress = action.payload;
        },
        connect: (state, action) => {
            state.currentAddress = action.payload;
        },
        disconnect: (state) => {
            state.currentAddress = null;
            state.clearSigner = true;
            return state;
        },
        signerReset: (state) => {
            state.clearSigner = false;
        }
    }

});

// Action creators are generated for each case reducer function
export const { connect, disconnect, signerReset, initContractAddress } = walletSlice.actions;

export default walletSlice.reducer;